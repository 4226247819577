<template>
    <card>
        Erfolgreich abgemeldet!

        <router-link to="/login">Zum Login</router-link>
    </card>
</template>

<script>
import Card from "@/components/UI/Card";

export default {
    name: "Logout",
    components: {Card}
}
</script>

<style scoped>

</style>