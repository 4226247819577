<template>
    <div class="bg-white overflow-hidden shadow rounded-lg my-8">
        <div class="px-4 py-5 border-b border-gray-200 sm:px-6" v-show="hasHeaderSlot">
            <div class="-ml-4 -mt-2">
                <div class="ml-4 mt-2">
                    <h3 class="text-lg leading-6 font-medium text-black text-center">
                        <slot name="header"></slot>
                    </h3>
                </div>
            </div>
        </div>

        <div class="px-4 py-5 sm:p-6">
            <slot></slot>
        </div>
    </div>

</template>

<script>
    export default {
        name: "Card",

        computed: {
            hasHeaderSlot () {
                return !!this.$slots['header'];
            }
        }
    }
</script>

<style scoped>

</style>
