<template>
    <card class="">
        <div class="w-full max-w-xs mx-auto">
            <div>
                <div v-show="!loggedIn">

                    <div v-show="step==='email'" class="border border-gray-500 px-8 pt-6 pb-8 mb-4">
                        <div class="mb-4">
                            <label class="block text-gray-700 text-sm font-bold mb-2"
                                   for="email">E-Mail</label>
                            <input tabindex="1" v-bind:class="{'border-red-500': error}" type="email" id="email"
                                   @keyup.enter="verifyEmail"
                                   class="appearance-none bg-gray-100 border w-full py-2 px-3 text-gray-700 outline-none focus:border-blue-600 focus:bg-white"
                                   placeholder="" v-model="email" autofocus required autocomplete="username">
                        </div>

                        <div class="mb-6 text-red-500 text-xs italic" v-if="error">
                            {{ errorText }}
                        </div>

                        <div class="flex justify-end">
                            <button-primary id="verify-email" tabindex="2" @click="verifyEmail"
                                            :class="{'opacity-25': error || loading}"
                                            v-bind:disabled="error || loading">
                                <span v-show="!loading">Weiter</span>
                                <span v-show="loading">
                                        <i class="fas fa-spinner fa-spin fa-fw"></i>
                                        <span class="sr-only">Loading...</span>
                                    </span>
                            </button-primary>
                        </div>

                    </div>

                    <div v-show="step==='password'">
                        <div class="border border-gray-500 px-8 pt-6 pb-8 mb-4">

                            <div class="mb-6 text-center">
                                <a @click="step='email'"
                                   class="cursor-pointer align-baseline text-gray-500 font-bold text-xs bg-blue-200 p-1 rounded">
                                    <span class="font-bold">{{ this.email }}</span>
                                    <i class="fas fa-chevron-down"></i>
                                </a>
                            </div>

                            <div class="mb-6">
                                <div class="flex justify-between">
                                    <label class="block text-gray-700 text-sm font-bold mb-2"
                                           for="password">Passwort</label>
                                    <router-link
                                        class="inline-block align-baseline text-gray-500 font-bold text-xs no-underline"
                                        to="password">Passwort vergessen?
                                    </router-link>
                                </div>
                                <input tabindex="2" :class="{'border-red-500': error}" type="password"
                                       id="password"
                                       @keyup.enter.prevent="authenticate"
                                       class="appearance-none bg-gray-100 border w-full py-2 px-3 text-gray-700 outline-none focus:border-blue-600 focus:bg-white"
                                       v-model="password" required autocomplete="current-password">
                            </div>

                            <div class="mb-6 text-red-500 text-xs italic" v-if="error">
                                {{ errorText }}
                            </div>

                            <div>
                                <button id="LoginButton" type="submit"
                                        @click="authenticate"
                                        :class="{'opacity-25': error || loading}"
                                        class="justify-center inline-flex w-full items-center py-4 border border-transparent text-base leading-6 font-medium rounded-lg text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:border-gray-600 focus:shadow-outline-indigo active:bg-gray-600 transition ease-in-out duration-150"
                                        v-bind:disabled="error || loading">
                                    <span v-show="!loading">Anmelden</span>
                                    <span v-show="loading">
                                        <i class="fas fa-spinner fa-spin fa-fw"></i>
                                        <span class="sr-only">Loading...</span>
                                    </span>
                                </button>
                            </div>

                        </div>
                    </div>

                    <div v-show="step==='saml'">
                        <div class="border border-gray-500 px-8 pt-6 pb-8 mb-4">

                            <div class="mb-6 text-center">
                                <a @click="step='email'"
                                   class="cursor-pointer align-baseline text-gray-500 font-bold text-xs bg-blue-200 p-1 rounded">
                                    <span class="font-bold">{{ this.email }}</span>
                                    <i class="fas fa-chevron-down"></i>
                                </a>
                            </div>

                            <p>
                                Anmelden via Single Sign On:
                                <a :href="link" class="w-full font-bold py-2 px-4 bg-blue-900 text-white">
                                    Anmelden
                                </a>
                            </p>
                        </div>
                    </div>

                    <div v-show="step==='unkown'">
                        Unbekannte Authentifizierungsmethode. Bitte aktualisiere deine App.
                    </div>

                    <div class="text-center">
                        <p class="mb-2">Neu bei OfficeEfficient?</p>
                        <a
                            target="_blank"
                            href="https://web.officeefficient.de/#/register"
                            class="block text-gray-500 font-bold text-sm no-underline">
                            Erstelle ein OfficeEfficient Konto
                        </a>
                    </div>

                </div>
                <div v-show="loggedIn" class="border border-gray-500 px-8 pt-6 pb-8 mb-4">
                    <router-link to="/">Zur Hauptseite</router-link>
                </div>
            </div>
        </div>

    </card>
</template>

<script>
import ButtonPrimary from "../components/UI/ButtonPrimary";
import Card from "../components/UI/Card";
import get from 'lodash/get';

export default {
    components: {Card, ButtonPrimary},
    data: () => ({
        email: '',
        password: '',
        error: null,
        loading: false,
        step: 'email',
        link: '',
    }),

    watch: {
        email() {
            this.error = null;
        },
        password() {
            this.error = null;
        }
    },

    mounted() {
        this.email = window.localStorage.getItem('email');
    },

    computed: {
        errorText() {
            if (this.error === 'invalid_credentials') {
                return "Falsche Zugangsdaten. Bitte nochmal probieren.";
            }

            return this.error;
        },
        loggedIn() {
            return this.$store.getters.loggedIn;
        },
    },

    methods: {
        verifyEmail() {
            this.loading = true;
            this.error = '';
            this.$http.post('accountlookup', {
                email: this.email,
            }).then(response => {
                this.loading = false;
                this.step = 'unkown';
                if (response.data.method === 'password') {
                    this.step = 'password';
                }
                if (response.data.method === 'saml') {
                    this.step = 'saml';
                    this.link = response.data.link;
                    window.open(this.link, '_system', 'usewkwebview=yes');
                }
            }).catch(error => {
                this.loading = false;
                if (!error.response) {
                    this.error = 'Verbindungsfehler. Bist du online?';
                    return;
                }
                this.error = get(error.response, 'data.errors.email[0]', 'Unbekannter Fehler.');
            })
        },
        authenticate() {
            this.loading = true;
            this.error = '';
            return this.$http
                .post('authenticate', {
                    email: this.email,
                    password: this.password,
                })
                .then(response => {
                    this.loading = false;
                    this.$store.commit('login', response.data.token);
                    this.$router.replace('/');
                })
                .catch(error => {
                    this.loading = false;
                    if (!error.response) {
                        this.error = 'Verbindungsfehler. Bist du online?';
                        return;
                    }
                    this.error = get(error.response, 'data.error', 'Unbekannter Fehler.');
                });
        }
    }
}
</script>
